import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const smilingFarmer = require("../images/smilingfarmer.jpeg")
const blueSkies = require("../images/blueskies.jpeg")
const linkedinsales = require("../images/linkedinsales.jpg")
const intechchat = require("../images/intechchat.jpg")

const IndexPage = () => (
  <Layout>
    <SEO title="About" description="" lang="en" meta={[]} />
    <div
      className="full-hero padded"
      style={{
        backgroundImage: `url(${smilingFarmer})`,
        backgroundPositionX: "60%",
      }}
    >
      <div className="container-fluid">
        <div className="d-flex w-100 align-items-start flex-column">
          <div className="mb-3 text-dark">
            <h2 className="m-0">OUR MISSION</h2>
          </div>
          <div>
            <h1
              className="text-light text-shadow-sm-yellow"
              style={{ maxWidth: "600px" }}
            >
              Enrich the lives of everyone on the planet and make the world more
              sustainable.
            </h1>
          </div>
        </div>
      </div>
    </div>

    {/* Content Block*/}
    <section
      className="full-hero padded"
      style={{
        backgroundImage: `url(${blueSkies})`,
        backgroundPositionX: "60%",
        backgroundPositionY: "top",
      }}
    >
      <div className="container-fluid">
        <div className="d-flex flex-column align-items-end">
          <div style={{ maxWidth: "1000px" }}>
            <h1 className="mb-5 p-4 text-light text-align-left text-shadow-sm-yellow">
              People and organizations rely on technology from us for every
              aspect of the technology landscape.
            </h1>
            <div className="row p-2">
              <div className="my-3 col-lg-6 col-md-12 col-sm-12">
                <div className="px-3">
                  <h3 className="bold">Healthcare</h3>
                  <p className="text-muted">
                    Deploy technology for accessible and equitable healthcare
                  </p>
                </div>
              </div>
              <div className="my-3 col-lg-6 col-md-12 col-sm-12">
                <div className="px-3">
                  <h3 className="bold">Education</h3>
                  <p className="text-muted">
                    Ignite the knowledge in every learner and empower the global
                    workforce
                  </p>
                </div>
              </div>
              <div className="my-3 col-lg-6 col-md-12 col-sm-12">
                <div className="px-3">
                  <h3 className="bold">Agriculture</h3>
                  <p className="text-muted">
                    Bring food to table with no stress, inclusive of underserved
                    communities
                  </p>
                </div>
              </div>
              <div className="my-3 col-lg-6 col-md-12 col-sm-12">
                <div className="px-3">
                  <h3 className="bold">Industrials</h3>
                  <p className="text-muted">
                    Build a sustainable environment that propels socioeconomic
                    growth
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Content Block */}
    <section className="bg-darkened">
      <h2 className="text-center mb-5">Our Principles</h2>
      <h3 className="text-center">
        These principles help define how we operate and what we value as an
        organization.
      </h3>

      <div className="container mt-8">
        <div className="row">
          <div className="my-3 col-lg-6 col-md-12">
            <div className="mx-3">
              <h3>Embrace aspiration</h3>

              <p className="text-muted">
                People are made up of aspirations. Aspirations not only embue
                meaning into the human experience, but they are a powerful force
                to realize goals that shape the future.
              </p>
            </div>
          </div>
          <div className="my-3 col-lg-6 col-md-12">
            <div className="mx-3">
              <h3>Respect everyone</h3>

              <p className="text-muted">
                We consider respect a necessity. It is an integral component in
                building fruitful relationships and teams, and ultimately in
                building successful organizations.
              </p>
            </div>
          </div>
          <div className="my-3 col-lg-6 col-md-12">
            <div className="mx-3">
              <h3>Be compassionate</h3>

              <p className="text-muted">
                Understanding the problems of others is the first step to
                solving them. Many of our intiatives focus on tackling the
                issues of others in order to improve the world we share.
              </p>
            </div>
          </div>
          <div className="my-3 col-lg-6 col-md-12">
            <div className="mx-3">
              <h3>Effective communication</h3>

              <p className="text-muted">
                Listening to understand, and communicating in a clear, concise
                way is something we try our best to uphold and improve across
                all areas of interaction.
              </p>
            </div>
          </div>
          <div className="my-3 col-lg-6 col-md-12">
            <div className="mx-3">
              <h3>Constant improvement</h3>

              <p className="text-muted">
                Iteration is something we know well and fully embrace at
                Flapmax. We invest heavily in our ability to improve our
                technology and methodologies overtime.
              </p>
            </div>
          </div>
          <div className="my-3 col-lg-6 col-md-12">
            <div className="mx-3">
              <h3>Enjoy work and inspire</h3>

              <p className="text-muted">
                People should enjoy the work they do and inspire their peers
                along the way. Not only does this improve wellbeing -
                <br />
                it improves work quality, too.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Content Block */}
    <section>
      <div className="container">
        <div className="row">
          <div className="my-3 col-lg-6 col-md-12">
            <h2 className="mb-5">Our Culture</h2>
            <p>
              Our culture is deeply connected to our principles. We provide a
              professional but highly compassionate environment, made up of
              diverse skill sets and backgrounds from all over the world, with
              the sole focus of improving ourselves and our platform.
            </p>
            <p>
              Having many perspectives helps us reduce our blindspots which
              ultimately allows us to create the best products possible. This is
              why we not only collaborate across the globe, but we keep an eye
              out for sources of unconventional talent.
            </p>
          </div>
          <div className="my-3 p-5 col-lg-6 col-md-12">
            <div className="d-flex justify-content-center align-items-center flex-column h-100 card">
              <h3 className=" mb-4">Read our organizational strategy</h3>
              <a
                href="https://blog.flapmax.com/articles/a-10-year-road-map-on-the-future-of-ai-and-flapmax"
                target="_blank"
              >
                <button className="btn btn-square btn-lg">Our Strategy</button>
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="my-3 col-lg-6 d-none d-lg-block">
            <div className="p-4">
              <img src={linkedinsales} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="my-3 col-lg-6">
            <div className="p-4">
              <img src={intechchat} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
